import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Loaders } from "../../../pages/Loaders";
import { uploadFile } from "../../apifile/ApiFiles";
import axios from "axios";

function CandidateCvsTable({ files, setFiles }) {

  const [load, setLoad] = useState(false)
  const onDrop = async (acceptedFiles) => {
    if (!acceptedFiles) {
      return
    }
    console.log(acceptedFiles[0]);

    const data = new FormData();
    data.append('file', acceptedFiles[0]);
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    try {
      const res = await axios.post('https://expert-eye.vercel.app/api/import/file', data);
      // const res = await uploadFile(data)
     
    } catch (error) {

    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".pdf,.doc,.docx",
  });

  const styles = {
    dropzone: {
      border: "2px dashed #007bff",
      borderRadius: "5px",
      padding: "20px",
      textAlign: "center",
      cursor: "pointer",
      transition: "border-color 0.3s ease-in-out",
    },
    dropzoneActive: {
      borderColor: "#28a745",
      backgroundColor: "#f8f9fa",
    },
    dropzoneText: {
      margin: "0",
      color: "#6c757d",
      fontSize: "1rem",
    },
    uploadedFiles: {
      marginTop: "10px",
    },
    uploadedFilesHeading: {
      fontSize: "1rem",
      fontWeight: "bold",
      marginBottom: "5px",
    },
    uploadedFilesList: {
      listStyleType: "disc",
      paddingLeft: "20px",
    },
    uploadedFileItem: {
      fontSize: "0.9rem",
      color: "#212529",
    },
  };

  return (
    <>
      {load && <Loaders />}
      <div className="pageTable">
        <form>
          <div className="row mb-3">
            <div className="form-group mb-3 col-xl-12 col-lg-12">
              <label htmlFor="language_level" className="mb-1">
                <strong>Drop Your File</strong>
              </label>
              <div
                {...getRootProps()}
                style={{
                  ...styles.dropzone,
                  ...(isDragActive ? styles.dropzoneActive : {}),
                }}
              >
                <input {...getInputProps()} />
                <p style={styles.dropzoneText}>
                  {isDragActive
                    ? "Drop the files here..."
                    : "Drag and drop files here, or click to select files"}
                </p>
              </div>
              <div style={styles.uploadedFiles}>
                {files.length > 0 && (
                  <>
                    <h6 style={styles.uploadedFilesHeading}>Uploaded Files:</h6>
                    <ul style={styles.uploadedFilesList}>
                      {files.map((file, index) => (
                        <li key={index} style={styles.uploadedFileItem}>
                          {file.name}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CandidateCvsTable;
