import axios from "axios";
import axiosInstance from "../login/AxiosServiseFactory";
import { baseUrl } from "../login/baseUrl";

export const login = (data) => {
  return axiosInstance.post(`${baseUrl}/user/loginAdmin`, data, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};

export const addUser = (data) => {
  return axiosInstance.post(`${baseUrl}/user/adduser`, data, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};


export const updateUser = (id,data) => {
  return axiosInstance.put(`${baseUrl}/user/updateUser/${id}`, data, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};

export const getAllUser = (count ,page) => {
  return axiosInstance.get(`${baseUrl}/user/get/alluser?count=${count}&page=${page}`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};
export const deleteUser = (id) => {
  return axiosInstance.delete(`${baseUrl}/user/delete/${id}`);
};

export const geteditUser = (id) => {
  return axiosInstance.get(`${baseUrl}/user/userId/${id}`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};


export const getSkill = () => {
  return axiosInstance.get(`${baseUrl}/skills`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};


export const addJob = (data) => {
  return axiosInstance.post(`${baseUrl}/jobs/addJobs`, data, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};



export const getJobsList = (count ,page) => {
  return axiosInstance.get(`${baseUrl}/jobs/allJobs?page=${page}&count=10`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};
export const deleteJobsList = (id) => {
 return axiosInstance.delete(`${baseUrl}/jobs/delete/${id}`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};

export const geteditjob = (id) => {
  return axiosInstance.get(`${baseUrl}/jobs/getId/${id}`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};

export const updateJob = (id,data) => {
  return axiosInstance.put(`${baseUrl}/jobs/updateJob/${id}`, data, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};


export const getCateg = (id) => {
  return axiosInstance.get(`${baseUrl}/category/jobCategory`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};


export const getLocation = (id) => {
  return axiosInstance.get(`${baseUrl}/cities`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};

export const getJobSkills = (id) => {
  return axiosInstance.get(`${baseUrl}/skills`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};
export const getCompany = (id) => {
  return axiosInstance.get(`${baseUrl}/company`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};
export const getIndustry = (id) => {
  return axiosInstance.get(`${baseUrl}/industry`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};

export const getUserByid = (id) => {
  return axiosInstance.get(`${baseUrl}/user/userId/${id}`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};

export const addCandidate = (data) => {
  return axiosInstance.post(`${baseUrl}/candidate/add`, data, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};

export const uploadFile = (data) => {
  return axios.post(`${baseUrl}/import/file`, data, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};

export const getCandidate = (page) => {
  return axiosInstance.get(`${baseUrl}/candidate?page=${page}&count=10`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};


export const getCandidateByid = (id) => {
  return axiosInstance.get(`${baseUrl}/candidate/get/${id}`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};



export const updateCandid = (id,data) => {
  return axiosInstance.put(`${baseUrl}/candidate/update/${id}`, data, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};


export const getHistoryLogin = (page) => {
  return axiosInstance.get(`${baseUrl}/user/logs-history`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  });
};


