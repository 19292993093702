import React from "react";
import { Link } from "react-router-dom";

function PositionTable({ data }) {
  return (
    <div className="pageTable">
      <div className="tableLength">
        <div className="dataTables_length">
          <select className="form-select">
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
          </select>
          <span className="record">records</span>
        </div>
      </div>
      <table className="table table-striped table-bordered ">
        <thead>

          <tr role="row" className="heading">
            <th>#</th>
            <th>User</th>
            <th>Login At</th>
            <th>Logout At</th>
            <th>Time</th>
            <th>Mail</th>
            <th>Mobile</th>
            {/* <th>Browser | OS</th> */}
          </tr>
        </thead>
        <tbody>

          {data && data?.map((item, i) => {
            return <tr key={i}>
              <td>{i + 1}</td>
              <td>{item?.firstname + " " + item?.lastname}</td>
              <td>{item?.lastLogin}</td>
              <td>{item?.lastLogout}</td>
              <td>{item?.hours} : {item?.minutes} : {item?.seconds}</td>
              <td>{item?.email}</td>
              <td>{item?.mobile}</td>
            </tr>
          })}

        </tbody>
      </table>
    </div>
  );
}

export default PositionTable;
