import React, { useEffect, useState } from "react";
import { FiSettings } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { Link, useParams } from "react-router-dom";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import PositionTable from "./positionTable/PositionTable";
import SideFilterComp from "./SideFilterComp";
import { Loaders } from "../../../pages/Loaders";

function ListResume() {
  const [data, setData] = useState(null)
  const parems = useParams()

  const constructQueryParams = (data) => {
    const params = new URLSearchParams();

    // Map only non-empty fields to the query params
    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        if (key === 'experienceFrom' && data.experienceTo) {
          params.append('jobExperience', `${data.experienceFrom}-${data.experienceTo}`);
        } else if (key === 'salaryFrom' && data.salaryTo) {
          params.append('expectedSalary', `${data.salaryFrom}-${data.salaryTo}`);
        } else if (!['experienceTo', 'salaryTo'].includes(key)) {
          params.append(key, value);
        }
      }
    });

    return params.toString();
  };

  const [load , setload] = useState(false)

  const baseUrl = 'https://expert-eye.vercel.app/api/apply/job/';
  const getData = async (page) => {
    const params = new URLSearchParams(parems.parems);
    const result = Object.fromEntries(params.entries());
    
    setload(false)
    const queryParams = constructQueryParams(result);
    const url = `${baseUrl}?${queryParams}&page=${page}`;


    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setData(data.data)
    } catch (error) {
      console.error('Fetch error:', error);
    }
    setTimeout(() => {
      setload(false)
    }, 1000);
  };
  useEffect(() => {
    getData(1)
  }, [])

  return (
    <div className="">
      {load && <Loaders/>}
      <div className="">
        {/* <div className="pageTitle">
          <FiSettings />
          <h5>Resume List</h5>
        </div> */}
        {/* <div className="addNew">
          <Link to="#" className="btn btn-success">
            <GoPlus /> Add New Position Types
          </Link>
        </div> */}
      </div>

      <div className="pageBody row">
        <div className="col-md-3" style={{ backgroundColor: "lightgray", borderRadius: "6px", padding: "10px 5px" }}>
          <SideFilterComp />
        </div>
        <div className="col-md-9">
          <PositionTable data={data}/>
        </div>
      </div>

      <div className="pageFooter">
        <div className="row">
          <div className="col-md-6">
            <div className="showEntry">
              <p>
                Showing 1 to 3 of 3 entries
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="paginationPart">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronLeft />
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronRight />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListResume;
