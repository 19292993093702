import React, { useEffect, useState } from 'react';
import { getSkill } from '../apifile/ApiFiles';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Loaders } from '../../pages/Loaders';

const AdvanceFields = ({ changeHandler2, initialVal, getById }) => {
  const [loader, setLoader] = useState(false)
  const [allState, setAllState] = useState({
    skills_id: '',
    country_id: '',
    states_id: '',
    city_id: '',
    currency_id: '',
    salery_periods_id: '',
    carrier_level_id: '',
    functional_area_id: '',
    job_type_id: '',
    job_shift_id: '',
    positions: '',
    gender_id: '',
    degree_level_id: '',
    job_experience: '',
  })
  const params = useParams()
  const getAllData = async () => {
    setLoader(true)
    const skill = await getSkill()

    const resCountryData = await axios.get(
      `https://expert-eye.vercel.app/api/countries`
    );

    const state = await axios.get(
      `https://expert-eye.vercel.app/api/states`
    );

    const citie = await axios.get(
      `https://expert-eye.vercel.app/api/cities`
    );



    const salary = await axios.get(
      `https://expert-eye.vercel.app/api/salary-period`
    );

    const functional = await axios.get(
      `https://expert-eye.vercel.app/api/functional-area`
    );

    const jobtype = await axios.get(
      `https://expert-eye.vercel.app/api/job-type`
    );

    const jobshift = await axios.get(
      `https://expert-eye.vercel.app/api/job-shift`
    );

    const gender = await axios.get(
      `https://expert-eye.vercel.app/api/gender`
    );

    // const position = await axios.get(
    //   `https://expert-eye.vercel.app/api/position`
    // );

    const degreLevel = await axios.get(
      `https://expert-eye.vercel.app/api/degree-level`
    );

    const jobExp = await axios.get(
      `https://expert-eye.vercel.app/api/job-experience`
    );
    const currenc = await axios.get(
      `https://expert-eye.vercel.app/api/currency/`
    );
    const industrie = await axios.get(
      `https://expert-eye.vercel.app/api/industries`
    );
    const resCareerData = await axios.get(
      `https://expert-eye.vercel.app/api/career`
    );
    setLoader(false)
    if (params?.id) {
      getById();
    }
    setAllState({
      ...allState,
      skills_id: skill,
      country_id: resCountryData.data,
      states_id: state.data,
      city_id: citie.data,
      salery_periods_id: salary.data,
      carrier_level_id: resCareerData.data,
      functional_area_id: functional.data,
      job_type_id: jobtype.data,
      job_shift_id: jobshift.data,
      gender_id: gender.data,
      // positions:position.data,
      degree_level_id: degreLevel.data,
      job_experience: jobExp.data,
      currency_id: currenc.data,
      industries: industrie.data,
    });
  }
  useEffect(() => {
    getAllData()
  }, [])

  return (
    <form>
      {loader && <Loaders />}
      <h5>Basic Details</h5>
      <div className="form-row row">
        <div className="form-group col-md-4">
          <label> Name</label>
          <input
            type="text"
            name="name"
            disabled
            value={initialVal.firstname + " " + initialVal.lastname}
            onChange={changeHandler2}
            className="form-control"
          />
        </div>

        <div className="form-group col-md-4">
          <label>Email</label>
          <input
            type="email"
            name="email"
            disabled
            value={initialVal.email}
            onChange={changeHandler2}
            className="form-control"
          />
        </div>
        <div className="form-group col-md-4">
          <label>Mobile</label>
          <input
            type="number"
            disabled
            name="mobile"
            value={initialVal.mobile}
            onChange={changeHandler2}
            className="form-control"
          />
        </div>
        <div className="form-group col-md-4">
          <label>Date of Birth</label>
          <input
            type="date"
            name="date_of_Birth"
            value={initialVal.date_of_Birth}
            onChange={changeHandler2}
            className="form-control"
          />
        </div>
        <div className="form-group col-md-4">
          <label>Age</label>
          <input
            type="number"
            name="age"
            value={initialVal.age}
            onChange={changeHandler2}
            className="form-control"
          />
        </div>
      </div>

      <h5>Location Details</h5>
      <div className="form-row row">
        <div className="form-group col-md-4">
          <label>Country</label>
          <select className="form-select" id="lang" value={initialVal?.country} name="country" onChange={changeHandler2}>
            <option value>Select Country</option>
            {allState?.country_id &&
              allState?.country_id?.map((item) => {
                return <option key={item._id} value={item._id}>{item.name ? item.name : item?.country}</option>;
              })}
          </select>
          {/* <input
            type="text"
            name="country"
            value={initialVal.country}
            onChange={changeHandler2}
            className="form-control"
          /> */}
        </div>
        <div className="form-group col-md-4">
          <label>State</label>
          <select className="form-select" id="lang" value={initialVal?.state} name="state" onChange={changeHandler2}>
            <option value>Select State</option>
            {allState?.states_id &&
              allState?.states_id?.map((item) => {
                return <option key={item._id} value={item._id}>{item.state}</option>;
              })}
          </select>
          {/* <input
            type="text"
            name="state"
            value={initialVal.state}
            onChange={changeHandler2}
            className="form-control"
          /> */}
        </div>
        <div className="form-group col-md-4">
          <label>City</label>
          <select className="form-select" id="lang" value={initialVal?.city} name="city" onChange={changeHandler2}>
            <option value>Select City</option>
            {allState?.city_id &&
              allState?.city_id?.map((item) => {
                return <option key={item._id} value={item._id}>{item.city}</option>;
              })}
          </select>
          {/* <input
            type="text"
            name="city"
            value={initialVal.city}
            onChange={changeHandler2}
            className="form-control"
          /> */}
        </div>
        <div className="form-group col-md-4">
          <label>Nationality</label>
          <select className="form-select" id="lang" value={initialVal?.nationality} name="nationality" onChange={changeHandler2}>
            <option value>Select Country</option>
            {allState?.country_id &&
              allState?.country_id?.map((item) => {
                return <option key={item._id} value={item._id}>{item.name ? item.name : item?.country}</option>;
              })}
          </select>
          {/* <input
            type="text"
            name="nationality"
            value={initialVal.nationality}
            onChange={changeHandler2}
            className="form-control"
          /> */}
        </div>
      </div>

      <h5>Professional Details</h5>
      <div className="form-row row">
        <div className="form-group col-md-4">
          <label>IT Skills</label>
          <select className="form-select" id="lang" value={initialVal?.itSkills} name="itSkills" onChange={changeHandler2}>
            <option value>Select Skill</option>
            {allState?.skills_id &&
              allState?.skills_id?.map((item) => {
                return <option key={item._id} value={item._id}>{item.job_skills}</option>;
              })}
          </select>
          {/* <input
            type="text"
            name="itSkills"
            value={initialVal.itSkills}
            onChange={changeHandler2}
            className="form-control"
          /> */}
        </div>
        <div className="form-group col-md-4">
          <label>Career Level</label>
          <select className="form-select" id="lang" value={initialVal?.careerLevel} name="careerLevel" onChange={changeHandler2}>
            <option value>Select Career level</option>
            {allState?.carrier_level_id &&
              allState?.carrier_level_id?.map((item) => {
                return <option key={item._id} value={item._id}>{item.career_level}</option>;
              })}
          </select>
          {/* <input
            type="text"
            name="careerLevel"
            value={initialVal.careerLevel}
            onChange={changeHandler2}
            className="form-control"
          /> */}
        </div>
        <div className="form-group col-md-4">
          <label>Industry</label>
          <select className="form-select" id="lang" value={initialVal?.industry} name="industry" onChange={changeHandler2}>
            <option value>Select Skill</option>
            {allState?.industries &&
              allState?.industries?.map((item) => {
                return <option key={item._id} value={item._id}>{item.industry}</option>;
              })}
          </select>
          {/* <input
            type="text"
            name="industry"
            value={initialVal.industry}
            onChange={changeHandler2}
            className="form-control"
          /> */}
        </div>
        <div className="form-group col-md-4">
          <label>Functional Area</label>
          <select className="form-select" id="lang" value={initialVal?.functionalArea} name="functionalArea" onChange={changeHandler2}>
            <option value>Select functional area</option>
            {allState?.functional_area_id &&
              allState?.functional_area_id?.map((item) => {
                return <option key={item._id} value={item._id}>{item.functional_area}</option>;
              })}
          </select>
          {/* <input
            type="text"
            name="functionalArea"
            value={initialVal.functionalArea}
            onChange={changeHandler2}
            className="form-control"
          /> */}
        </div>
        <div className="form-group col-md-4">
          <label>Job Experience</label>
          <select className="form-select" id="lang" value={initialVal?.jobExperience} name="jobExperience" onChange={changeHandler2}>
            <option value>Select Job Exp</option>
            {allState?.job_experience &&
              allState?.job_experience?.map((item) => {
                return <option key={item._id} value={item._id}>{item.job_experience}</option>;
              })}
          </select>
        </div>
      </div>

      <h5>Experience and Salary</h5>
      <div className="form-row row">
        <div className="form-group col-md-4">
          <label>Experience (From)</label>
          <input
            type="number"
            name="experienceFrom"
            value={initialVal.experienceFrom}
            onChange={changeHandler2}
            className="form-control"
          />
        </div>
        <div className="form-group col-md-4">
          <label>Experience (To)</label>
          <input
            type="number"
            name="experienceTo"
            value={initialVal.experienceTo}
            onChange={changeHandler2}
            className="form-control"
          />
        </div>
        <div className="form-group col-md-4">
          <label>Expected Salary</label>
          <input
            type="number"
            name="salaryFrom"
            value={initialVal.salaryFrom}
            onChange={changeHandler2}
            className="form-control"
          />
        </div>
        <div className="form-group col-md-4">
          <label>Current Salary</label>
          <input
            type="number"
            name="salaryTo"
            value={initialVal.salaryTo}
            onChange={changeHandler2}
            className="form-control"
          />
        </div>
        <div className="form-group col-md-4">
          <label>Salary Currency</label>
          <input
            type="text"
            name="salaryCurrency"
            value={initialVal.salaryCurrency}
            onChange={changeHandler2}
            className="form-control"
          />
        </div>
      </div>

      <h5>Search Keywords</h5>
      <div className="form-row row">
        <div className="form-group col-md-4">
          <label>Keywords</label>
          <input
            type="text"
            name="keywords"
            value={initialVal.keywords}
            onChange={changeHandler2}
            className="form-control"
          />
        </div>
        <div className="form-group col-md-4">
          <label>Exclusive Keywords</label>
          <input
            type="text"
            name="exclusiveKeywords"
            value={initialVal.exclusiveKeywords}
            onChange={changeHandler2}
            className="form-control"
          />
        </div>
      </div>

      {/* <button type="submit" className="btn btn-primary mt-3">
        Submit
      </button> */}
    </form>
  );
};

export default AdvanceFields;
