import axios from "axios";
import { useEffect, useState } from "react";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import JoditEditor from 'jodit-react';
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';
import { addCandidate, getCandidateByid, getCateg, getLocation, updateCandid } from "../../apifile/ApiFiles";
import { Accordion } from "react-bootstrap";
import AdvanceFileds from "../../search-candidate/AdvanceFileds";
import { Loaders } from "../../../pages/Loaders";
function AddCandidateTable() {
  const [categorydata, setCategorydata] = useState();
  const [locationdata, setLocationdata] = useState();

  const params = useParams();
  const navigate = useNavigate();

  const [initialVal, setInitialVal] = useState({
    firstname: "",
    jobCategory: "",
    email: null,
    email2: null,
    mobile: "",
    mobile2: null,
    location: "",
    to: "12/2/2024",
    from: "12/2/2024",
    is_active: null,


    keywords: '',
    exclusiveKeywords: '',
    itSkills: '',
    jobExperience: '',
    experienceTo: '',
    currentLocation: '',
    salaryFrom: '',
    salaryTo: '',
    age: '',
    lastname: '',
    email: '',
    date_of_Birth: '',
    country: '',
    state: '',
    city: '',
    nationality: '',
    gender: '',
    mobile: '',
    careerLevel: '',
    industry: '',
    functionalArea: '',
    jobExperience: '',
    expectedSalary: '',
    currentSalary: '',
    salaryCurrency: '',
  });

  const changeHandler = (e) => {
    const clone = { ...initialVal };
    const value = e.target.value;
    const name = e.target.name;
    if (name == "mobile2") {
      if (value.length == 11) {
        return;
      }
    }
    if (name == "mobile") {
      if (value.length == 11) {
        return;
      }
    }

    clone[name] = value;
    setInitialVal(clone);
  };
  const [genders, setGenders] = useState(null)
  const getById = async (id) => {
    try {
      const res = await getCandidateByid(params?.id)
      console.log(res);
      
      setInitialVal(res);
    } catch (error) {

      alert('s')
    }
  };

  const getData = async () => {
    try {
      const rescategory = await getCateg()
      const maped = rescategory?.map((item) => {
        return { ...item, value: item.category_name, label: item.category_name }
      })
      setCategorydata(maped);

    } catch (error) {
      alert("wrog");
    }
  };
  const getData1 = async () => {
    try {

      const reslocation = await getLocation()
      setLocationdata(reslocation);
    } catch (error) {
      alert("wrog");
    }
  };

  const getGender = async (id) => {
    try {
      const gender = await axios.get(
        `https://expert-eye.vercel.app/api/gender`
      );
      setGenders(gender?.data);
    } catch (error) {

    }
  };

  useEffect(() => {
    getData();
    getGender()
    getData1();
   
  }, []);

  const notify = (updateMassage) => toast(updateMassage);
  const [loader, setLoader] = useState(false)
  const handleSubmit = async () => {
    setLoader(true)
    // console.log(initialVal);
    // return
    if (params?.id) {
      try {
        // const res = await axios.put(
        //   `https://abaris-j-p-backend.vercel.app/api/candidate/update/${params?.id}`,
        //   initialVal
        // );
        const res = await updateCandid(params?.id,{...initialVal,jobSkills:[initialVal?.itSkills]})
        if (res.firstname) {
          notify("updated Successfull");
        } else {
          alert(res.message)
          setLoader(false)
          return
        }
       
        setTimeout(() => {
          navigate("/admin/candidate-list");
        }, 1000);
      } catch (error) {
        alert(error?.response?.data?.message)
      }
    } else {
      try {
        const res = await addCandidate({...initialVal,jobSkills:[initialVal?.itSkills]})
        if (res.firstname) {
          notify("Add Successfull");
        } else {
          alert(res.message)
          setLoader(false)
          return
        }
        setTimeout(() => {
          navigate("/admin/candidate-list");
        }, 1000);
      } catch (error) {
        // console.log();
        alert(error?.response?.data?.message)
      }
      setLoader(false)
    }
  };

  const [showCateg, setShoCagte] = useState([])
  const onchangeHandleCateg = (e) => {
    const clone = { ...initialVal, jobCategory: e._id }
    setShoCagte([{ ...e }])
    setInitialVal(clone)
  }
  const changeHandler2 = async (e) => {
    const file = e.target.files[0];
    const allowedExtensions = /(\.pdf|\.xls|\.txt|\.doc|\.docx)$/i;

    if (!file) {
      return
    }

    if (!allowedExtensions.exec(file.name)) {
      alert('Only .PDF, .XLS, .TXT, .DOC, .DOCX files are allowed.');
      setInitialVal({
        ...initialVal,
        resume: null,
      });
      return;
    }
    const data = new FormData();
    data.append('file', file);

    try {
      const res = await axios.post('https://expert-eye.vercel.app/api/upload/add', data);
      setInitialVal({
        ...initialVal,
        resume: res.data
      });
    } catch (error) {
      const pdfUrl = error.response.data.stack
      console.log(pdfUrl);
      const match = pdfUrl.match(/file-\d+\.pdf/);
      if (match) {
        setInitialVal({
          ...initialVal,
          resume: match[0],
        });
        console.log(match[0]); // Outputs: file-1732781921917.pdf
      }
    }
  }

  return (
    <>
      <div className="pageTable">
        {loader && <Loaders />}
        <ToastContainer />
        <form>
          <div className="row mb-3">
            <div className="form-group mb-3 col-xl-6 col-lg-6">
              <label htmlFor="language_level">
                <strong>First Name</strong>
              </label>
              <input
                className="form-control"
                placeholder="First Name"
                type="text"
                name="firstname"
                value={initialVal.firstname}
                onChange={changeHandler}
              />
            </div>
            <div className="form-group mb-3 col-xl-6 col-lg-6">
              <label htmlFor="language_level">
                <strong>Last Name</strong>
              </label>
              <input
                className="form-control"
                placeholder="last name"
                type="text"
                name="lastname"
                value={initialVal.lastname}
                onChange={changeHandler}
              />
            </div>

            <div className="form-group mb-3 col-xl-6 col-lg-6">
              <label htmlFor="lang" className="mb-1">
                <strong>Job Category</strong>
              </label>
              <Select
                // isMulti
                defaultValue={showCateg}
                value={showCateg}
                name="category"
                options={categorydata}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={onchangeHandleCateg}
              />
            </div>

            <div className="form-group mb-3 col-xl-6 col-lg-6">
              <label htmlFor="language_level" className="mb-1">
                <strong>Email 1</strong>
              </label>
              <input
                className="form-control"
                placeholder="Email 1"
                type="Email"
                name="email"
                value={initialVal.email}
                onChange={changeHandler}
              />
            </div>

            <div className="form-group mb-3 col-xl-6 col-lg-6">
              <label htmlFor="language_level" className="mb-1">
                <strong>Alt Email</strong>
              </label>
              <input
                className="form-control"
                placeholder="Email 2"
                type="Email"
                disabled={params?.id}
                name="email2"
                value={initialVal.email2}
                onChange={changeHandler}
              />
            </div>

            <div className="form-group mb-3 col-xl-6 col-lg-6">
              <label htmlFor="language_level">
                <strong>Mobile 1</strong>
              </label>
              <input
                className="form-control"
                placeholder="+91 "
                type="number"
                name="mobile"
                value={initialVal.mobile}
                onChange={changeHandler}
              />

              {initialVal.mobile?.length < 10 && (
                <p style={{ color: "red" }}>Number must be 10 degit</p>
              )}
            </div>

            <div className="form-group mb-3 col-xl-6 col-lg-6">
              <label htmlFor="language_level">
                <strong>Alt Mobile </strong>
              </label>
              <input
                className="form-control"
                placeholder="+91"
                type="number"
                name="mobile2"
                disabled={params?.id}
                value={initialVal.mobile2}
                onChange={changeHandler}
              />
              {initialVal.mobile2?.length < 10 && (
                <p style={{ color: "red" }}>Number must be 10 degit</p>
              )}
            </div>


            <div className="form-group mb-3 col-xl-6 col-lg-6">
              <label htmlFor="language_level">
                <strong>Password </strong>
              </label>
              <input
                className="form-control"
                disabled={params?.id}
                placeholder="password"
                type="password"
                name="password"
                value={initialVal.password}
                onChange={changeHandler}
              />
            </div>



            {/* <div className="form-group mb-3 col-xl-6 col-lg-6">
              <label htmlFor="lang" className="mb-1">
                <strong>Location</strong>
              </label>
              <select
                className="form-select"
                onChange={changeHandler}
                name="location"
                // value={initialVal?.location._id}
              >
                <option>Select Location</option>
                {locationdata &&
                  locationdata.map((item) => {
                    return (
                      <option value={item._id}>{item.city}</option>
                    );
                  })}
              </select>
            </div> */}

            <div className="form-group mb-3 col-xl-6 col-lg-6">
              <label htmlFor="lang" className="mb-1">
                <strong>Gender</strong>
              </label>
              <select className="form-select" id="lang" value={initialVal?.gender} name="gender" onChange={changeHandler}>
                <option value>Select Gender</option>
                {genders &&
                  genders?.map((item) => {
                    return <option key={item._id} value={item._id}>{item.gender}</option>;
                  })}
              </select>
            </div>

            <div className="form-group mb-3 col-xl-6 col-lg-6">
              <label htmlFor="lang" className="mb-1">
                <strong>Upload CV</strong>
              </label>
              <input className="form-control" type="file" onChange={changeHandler2} name="cv" />
            </div>


            <div className="form-group col-12">
              <label htmlFor="lang" className="mb-1">
                <strong>Open Advance Feild To Fill Extra Fileds</strong>
              </label>

            </div>
            {/* Advance fileds */}
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Advance Feild</Accordion.Header>
                <Accordion.Body>
                  <AdvanceFileds changeHandler2={changeHandler} initialVal={initialVal} getById={getById}/>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>



            <div className="form-group mb-3 ">
              <label htmlFor="language_level">
                <strong>Candidate description</strong>
              </label>
              <JoditEditor
              // value={content}
              // onChange={handleContentChange}
              />
            </div>

            <div className="form-group mb-3 ">
              <label htmlFor="is_default">
                <strong>Is Active?</strong>
              </label>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="is_active"
                  value={1}
                  onChange={changeHandler}
                  // checked={initialVal?.is_active == 1}
                  defaultChecked
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Yes
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="is_active"
                  value={0}
                  // checked={initialVal?.is_active == 0}
                  onChange={changeHandler}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  No
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>

      <button
        className="btn btn-large btn-primary"
        type="button"
        onClick={handleSubmit}
      >
        Save <BsFillArrowRightCircleFill />
      </button>
    </>
  );
}
export default AddCandidateTable;
