import React from "react";
import { Link } from "react-router-dom";

function PositionTable() {
  return (
    <div className="pageTable">
      <div className="tableLength">
        <div className="dataTables_length">
          <select className="form-select">
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
          </select>
          <span className="record">records</span>
        </div>
      </div>
      <table className="table table-striped table-bordered ">
        <thead>
        
          <tr role="row" className="heading">
            <th>User</th>
            <th>Send</th>
            <th>Sender</th>
            <th>Subject</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
         
        </tbody>
      </table>
    </div>
  );
}

export default PositionTable;
