import React, { useEffect, useState } from "react";
import PageBar from "../../components/dashboard/page-bar/PageBar";
import Jobs from "../../components/jobs/Jobs";
import axios from "axios";
import { deleteJobsList, getJobsList } from "../../components/apifile/ApiFiles";
import { baseUrl } from "../../components/login/baseUrl";

const JobsPage = () => {
  const [data, setData] = useState();

  const getJobs = async (page) => {
    try {
      const res = await getJobsList(10, 3)
      setData(res);
    } catch (error) {
      alert("Error");
    }
  };
  const getJobs2 = async (page) => {
    try {
      const res = await axios.get(
        `${baseUrl}jobs/allJobs?page=${page}&count=10`
      );
      setData(res.data);
    } catch (error) {
      alert("Error");
    }
  };

  useEffect(() => {
    getJobs(1);
  }, []);

  const handleDelete = async (id) => {
    try {
      const res = await deleteJobsList(id)
      getJobs()
    } catch (error) {
      alert("Error");
    }
  };

  return (
    <>
      <div className="pageWrapper">
        <PageBar title="Jobs" />
        <h3 className="page-title">
          Manage Jobs <small>Jobs</small>
        </h3>
        <Jobs data={data} handleDelete={handleDelete} getJobs={getJobs2} />
      </div>
    </>
  );
};

export default JobsPage;
