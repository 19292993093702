import React, { useEffect, useState } from "react";
import PageBar from "../../components/dashboard/page-bar/PageBar";
import CandidateListProfile from "../../components/candidateProfile/candidate-listProfile/CandidateListProfile";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Loaders } from "../Loaders";
import { baseUrl } from "../../components/login/baseUrl";
import { getCandidate } from "../../components/apifile/ApiFiles";

const CandidateListPage = () => {
  const [data, setData] = useState();
  const [loader, setLoader] = useState(false)
  const getCandidatelistData2 = async (page) => {
    setLoader(true)
    try {
      const res = await axios.get(
        `${baseUrl}/candidate/all?page=${page}`
      );
      setData(res.data);
    } catch (error) { }
    setLoader(false)
  };
  const getCandidatelistData = async (page) => {
    setLoader(true)
    try {
      const res = await getCandidate(page)
      if (res.status_code == 403) {
        setLoader(false)
        return
      }
      setData(res);
    } catch (error) { }
    setLoader(false)
  };

  useEffect(() => {
    getCandidatelistData(1);
  }, []);


  const getCandidatelistDataFilter = async (val) => {
    setLoader(true)
    // let url = `https://abaris-j-p-backend.vercel.app/api/candidate/filter`
    let clone = {}
    for (const key in val) {
      if (val[key].length > 0) {
        let clone2 = { ...clone, [key]: val[key] }
        clone = clone2
      }
    }
    try {
      const res = await axios.get(
        `${baseUrl}/candidate/search?name=${clone?.name ? clone.name : ''}&mobile=${clone?.mobile ? clone.mobile : ''}&email=${clone?.email ? clone.email : ''}&job_category=${clone?.job_category ? clone.job_category : ''}&location=${clone?.location ? clone.location : ''}&status=${clone?.status ? clone.status : ''}`,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        }
      );

      console.log(res);

      setData(res.data)
    } catch (error) { }

    setLoader(false)
  };


  return (
    <>
      {loader && <Loaders />}
      <Helmet>
        <title>Candidate List | job portal</title>
        <meta name="keyword" content="Jobs, Find Job, Give " />
        <meta name="description" content="" />
      </Helmet>
      <div className="pageWrapper">
        <PageBar title="Candidate" />
        <h3 className="page-title">
          List Candidate <small>Candidate</small>
        </h3>
        <CandidateListProfile
          getCandidatelistDataFilter={getCandidatelistDataFilter}
          data={data}
          getCandidatelistData={getCandidatelistData}
          getCandidatelistData2={getCandidatelistData2}
        />
      </div>
    </>
  );
};

export default CandidateListPage;
