import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineFileDownload } from "react-icons/md";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import Modal from 'react-bootstrap/Modal';
import ChartsBody from "../../../charts/ChartsBody";
import BulkModel from "./BulkModel";

const CandidateTable = ({ data, getCandidatelistData, count }) => {
  console.log(data);
  
  const [show, setShow] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleCandidateDelete = async (id) => {
    try {
      const delet = await axios.delete(
        `https://abaris-j-p-backend.vercel.app/api/candidate/delete/${id}`
      );
      getCandidatelistData(count);
    } catch (error) { }
  };

  const handleShow = () => {
    setShow(!show);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allCandidateIds = data.map((candidate) => candidate._id);
      setSelectedCandidates(allCandidateIds);
    } else {
      setSelectedCandidates([]);
    }
  };

  const handleSelectOne = (id) => {
    if (selectedCandidates.includes(id)) {
      setSelectedCandidates(selectedCandidates.filter((candidateId) => candidateId !== id));
    } else {
      setSelectedCandidates([...selectedCandidates, id]);
    }
  };

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div className="pageTable" 
      // onMouseDown={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}
      >
        <div className="tableLength">
          <div className="dataTables_length">
            <select name="languageLevelDatatableAjax_length" className="form-select">
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>{" "}
            <span className="record">records</span>
            <button className="btn btn-success" style={{margin:"0 10px"}} onClick={() => setModalShow(true)}>Send Bulk Message</button>
          </div>
        </div>

        <BulkModel
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

        <table className="table table-striped table-bordered">
          <thead>
            <tr role="row" className="heading">
              <th>
                <div className="form-check">
                  <input
                    className="form-check-input selectAll"
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    id="flexCheckDefault"
                  />
                </div>
              </th>
              <th>S.no</th>
              {/* <th>Date</th> */}
              <th className="candidate_name_heading">Name</th>
              <th>Job category</th>
              <th>Mobile</th>
              <th>Mobile 2</th>
              <th>Email</th>
              <th>Location</th>
              {(window.localStorage.getItem("role") === "admin" || window.localStorage.getItem("role") === "super admin") && <th>Docs</th>}
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            {data && data?.map((item, i) => (
              <tr role="row" className="odd" key={item._id}>
                <td>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={selectedCandidates.includes(item._id)}
                      onChange={() => handleSelectOne(item._id)}
                      id="flexCheckDefault"
                    />
                  </div>
                </td>
                <td><span>{i + 1}</span></td>
                {/* <td><span>{new Intl.DateTimeFormat('en-US', options).format(new Date(item.created_at))}</span></td> */}
                <td><span>{item.firstname} {item.lastname}</span></td>
                <td>{item.jobCategory?.category_name}</td>
                <td>{item.mobile}</td>
                <td>{item.mobile2 || '---'}</td>
                <td>{item.email}</td>
                <td>{item.location?.location_name}</td>
                {(window.localStorage.getItem("role") === "admin" || window.localStorage.getItem("role") === "super admin") && (
                  <td>
                    <button className="btn btn-success" type="button" style={{ fontSize: "12px" }}>
                      <MdOutlineFileDownload /> Download Docs
                    </button>
                  </td>
                )}
                <td>
                  <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      Action
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to={`#`} onClick={() => handleShow(true)}>
                          Chart
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to={`edit/${item._id}`}>
                          Edit
                        </Link>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Mark in Active
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal show={show} size="lg" fullscreen={true}>
        <Modal.Header>
          <Modal.Title>Charts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChartsBody />
          <div style={{ display: "flex" }}>
            <input placeholder="Drop Your Message!" className="form-control" />
            <button type="button" style={{ padding: "0 40px" }} className="btn btn-success">Send</button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleShow}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CandidateTable;
