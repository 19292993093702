import React, { useEffect, useState } from "react";
import logo from "../../assets/image/logo/eye.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Loaders } from "../../pages/Loaders";
import { login } from "../apifile/ApiFiles";
function Login() {
  const [initialVal, setInitialVal] = useState({
    email: "",
    password: "",
   
  });
  const [loginUser, setLoginUser] = useState(false)

  const changeHandler = (e) => {
    const clone = { ...initialVal };
    const value = e.target.value;

    const name = e.target.name;
    clone[name] = value;
    setInitialVal(clone);
  };

  const navigate = useNavigate();

  const notify = (updateMassage) => toast(updateMassage);

  const [idSave, setIpSave] = useState('')

  const getData = async () => {
    // const res = await axios.get("https://api.ipify.org/?format=json");


    // const deviceDetector = new DeviceDetector();
    // const userAgent = "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.81 Safari/537.36";
    // const device = deviceDetector.parse(userAgent);

    // console.log(deviceDetector);
    // setIpSave(res.data)

    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const randomString = Math.random().toString(20).substring(2, 14) + Math.random().toString(20).substring(2, 14);

    const deviceID = `${userAgent}-${platform}-${randomString}`;
    console.log(deviceID)

  };

  useEffect(() => {
    getData()
  }, [])
  const [loader, setLoader] = useState(false)
  const handleSubmit = async () => {
    setLoader(true)
    // window.localStorage.setItem('token', 'token')
    try {
      const res = await login(initialVal)
      if (res.statusCode == 200) {
        notify("Login Successfull");
      } else {
        alert(res.message)
        setLoader(false)
        return
      }
      window.localStorage.setItem('token', res.token)
      window.localStorage.setItem('role', res.role)
      setTimeout(() => {
        navigate("/admin");
      }, 1000);
    } catch (error) {
      alert(`Wrong Password or Email Detect ${error?.message}`);
    }
    setLoader(false)
  };

  const handleSubmitUser = async () => {
    window.localStorage.setItem('token', 'token')
    // navigate("/admin");
    // return
    try {
      const res = await login(initialVal)
      if (res.statusCode == 200) {
        notify("Login Successfull");
      } else {
        alert(res.message)
        setLoader(false)
        return
      }
      window.localStorage.setItem('token', res.token)
      setTimeout(() => {
        navigate("/admin");
      }, 1000);
    } catch (error) {
      alert("Wrong Password or Email Detect");
    }


  };


  return (
    <section className="login_main_sec">
      {loader && <Loaders />}
      <ToastContainer />
      <div className="container">
        <div className="login_card">
          <div className="login_logo_container">
            <img src={logo} alt="" className="login_logo" />
          </div>

          <div className="login_form_container">
            <div className="header-area">
              <h4 className="lgo">Login to your account.</h4>
            </div>
            <form>
              <div className="mb-4">
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                  name="email"
                  value={initialVal.email}
                  onChange={changeHandler}
                />
              </div>
              <div className="mb-4">
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  name="password"
                  value={initialVal.password}
                  onChange={changeHandler}
                />
              </div>

              <div className="forgot mb-4">
                <div className=" form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Remember me
                  </label>
                </div>
                {/* <div className=" form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck2"
                    checked={loginUser}
                    onChange={() => { setLoginUser(!loginUser) }}
                  />
                  <label className="form-check-label" htmlFor="exampleCheck2">
                    Login As User
                  </label>
                </div> */}
                <div>
                  <a href="">forgot Password</a>
                </div>
              </div>


              <button
                type="button"
                className="btn btn-primary btn_login"
                onClick={loginUser ? handleSubmitUser : handleSubmit}
              >
                {loginUser ? 'LOGIN USER' : 'LOGIN ADMIN'}

              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
