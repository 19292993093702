import React, { useEffect, useState } from "react";
import { FiSettings } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { Link } from "react-router-dom";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import PositionTable from "./positionTable/PositionTable";
import { getHistoryLogin } from "../apifile/ApiFiles";
import { Pagination } from "antd";
import { Loaders } from "../../pages/Loaders";

function HistoryList() {
  const [load ,setLoad] = useState(false)
  const [data, setData] = useState(null)
  const getData = async (page) => {
    setLoad(true)
    try {
      const res = await getHistoryLogin(page)
      setData(res.data)
    } catch (error) {

    }
    setLoad(false)
  }
  useEffect(() => {
    getData()
  }, [])
  const onChangeVal = (e) => {
    // console.log(e);
    getData(e)

  };
  return (
    <div className="pageTableWrapper">
      {load && <Loaders/>}
      <div className="pageHeader">
        <div className="pageTitle">
          <FiSettings />
          <h5>Login History</h5>
        </div>
      </div>

      <div className="pageBody">
        <PositionTable data={data}/>
      </div>

      <div className="pageFooter">
        <div className="row">

          <div className="col-md-6">
            <div className="paginationPart">
              <Pagination
                defaultCurrent={1}
                // onChange={onChangeVal}
                // total={data?.totalCandidate}
              />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoryList;
