import React, { useEffect, useState } from 'react';
import './CandidateDetail.css';
import image from "../../assets/image/images/user.png"
import { useParams } from 'react-router-dom';
import { Loaders } from '../../pages/Loaders';
import axios from 'axios';
import { getUserByid } from '../apifile/ApiFiles';
import { baseUrl } from '../login/baseUrl';

const CandidateDetail = () => {
  // Example data
  const candidate = {
    name: "John Doe",
    jobTitle: "Software Engineer",
    profilePic: image,
    email: "john.doe@example.com",
    phone: "+123456789",
    linkedin: "https://www.linkedin.com/in/johndoe/",
    currentCompany: "Tech Inc.",
    previousCompany: "StartUp Co.",
    experience: [
      { role: "Senior Developer", company: "Tech Inc.", years: "2018-2023" },
      { role: "Junior Developer", company: "StartUp Co.", years: "2015-2018" },
    ],
    education: [
      { degree: "B.Sc. Computer Science", institution: "University of Tech", year: "2015" }
    ],
    skills: ["JavaScript", "React", "Node.js", "CSS", "Git", "Rest API"],
    projects: [
      {
        title: "E-Commerce Platform",
        description: "Developed a full-stack e-commerce web application using React and Node.js.",
        role: "Lead Developer",
        technologies: ["React", "Node.js", "MongoDB", "Express"],
        teamSize: 5,
        challenges: "Scalability and performance optimization under heavy load.",
        duration: "2021-2023"
      },
      {
        title: "Internal CRM Tool",
        description: "Built a CRM tool for managing customer interactions.",
        role: "Backend Developer",
        technologies: ["Node.js", "Express", "MySQL"],
        teamSize: 3,
        challenges: "Data migration from legacy system and ensuring data integrity.",
        duration: "2019-2021"
      },
    ],
    resume: "https://example.com/resume.pdf",
  };

  const [load, setload] = useState(false)
  const [data, setData] = useState()
  const [resum, setResum] = useState()
  const parems = useParams()
  const getData = async (e) => {
    setload(false)
    try {
      const response = await getUserByid(parems.id)
     
      setData(response.data)
      const res = await axios.get(`${baseUrl}/upload/${response.data?.resume}`, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      });
    } catch (error) {
      console.error('Fetch error:', error);
    }
    setTimeout(() => {
      setload(false)
    }, 1000);
  };
  useEffect(() => {
    getData()
  }, [])

  return (
    <div className="candidate-detail">
      {/* Profile Section */}
      {load && <Loaders />}
      <div className="profile-section">
        <img src={candidate.profilePic} alt="Profile" className="profile-pic" />
        <h1>{data?.firstname} {data?.lastname}</h1>
        <h3>{data?.careerLevel?.career_level}</h3>
        <p>Current Company: <strong>--</strong> Previous Company: <strong>--</strong></p>
      </div>

      {/* Contact Information */}
      <div className="contact-section">
        <h4>Contact Information</h4>
        <p>Email: <a href={`mailto:${data?.email}`}>{data?.email}</a></p>
        <p>Phone: <a href={`tel:${data?.mobile}`}>{data?.mobile}</a></p>
        <p>LinkedIn: <a href={data?.linkedin} target="_blank" rel="noopener noreferrer">{data?.linkedin}</a></p>
      </div>

      {/* Experience Section */}
      <div className="experience-section">
        <h4>Experience</h4>
        <ul>
          <li>Total Exp : {data?.jobExperience?.job_experience}</li>
          {candidate.experience.map((exp, index) => (
            <li key={index}>
              <strong>{exp.role}</strong> --            </li>
          ))}
        </ul>
      </div>

      {/* Education Section */}
      <div className="education-section">
        <h4>Education</h4>
        <ul>
          {candidate.education.map((edu, index) => (
            <li key={index}>
              <strong>{edu.degree}</strong> from {edu.institution} ({edu.year})
            </li>
          ))}
        </ul>
      </div>

      {/* Skills Section */}
      <div className="skills-section">
        <h4>Skills</h4>
        <div className="skills">
          {candidate.skills.map((skill, index) => (
            <span key={index} className="skill-badge">{skill}</span>
          ))}
        </div>
      </div>

      {/* Projects Section */}
      <div className="projects-section">
        <h4>Projects</h4>
        <ul>
          {candidate.projects.map((project, index) => (
            <li key={index}>
              <h5>{project.title}</h5>
              <p><strong>Description:</strong> {project.description}</p>
              <p><strong>Role:</strong> {project.role}</p>
              <p><strong>Technologies:</strong> {project.technologies.join(", ")}</p>
              <p><strong>Team Size:</strong> {project.teamSize}</p>
              <p><strong>Challenges:</strong> {project.challenges}</p>
              <p><strong>Duration:</strong> {project.duration}</p>
            </li>
          ))}
        </ul>
      </div>

      {/* Documents Section */}
      <div className="documents-section">
        <h4>Documents</h4>
        <a href={candidate.resume} target="_blank" rel="noopener noreferrer" className="document-link">
          Download Resume
        </a>
      </div>
    </div>
  );
};

export default CandidateDetail;
