import { Button, Popconfirm } from "antd";
import React from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";

const CategoryTable = ({ data, getCategoryData }) => {
  const handleCandidateDelete = async (id) => {
    try {
      // await axios.delete(`https://example.com/api/candidate/delete/${id}`);
      getCategoryData(); 
    } catch (error) {
      console.error("Error deleting candidate:", error);
    }
  };

  return (
    <div className="pageTable">
      <table className="table table-striped table-bordered" style={{width:"1400px"}}>
        <thead>
          <tr role="row" className="heading">
            <th>S.No</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Gender</th>
            <th>Country</th>
            <th>State</th>
            <th>City</th>
            <th>Job Experience</th>
            <th>Job Skills</th>
            <th>Status</th>
            <th>Date of Birth</th>
          </tr>
        </thead>
        <tbody>
          {data?.length ? (
            data.map((item, i) => (
              <tr key={item._id}>
                <td>{i + 1}</td>
                <td>{item.firstname}</td>
                <td>{item.lastname}</td>
                <td>{item.email}</td>
                <td>{item.phone}</td>
                <td>{item.gender}</td>
                <td>{item.country}</td>
                <td>{item.state}</td>
                <td>{item.city}</td>
                <td>{item.jobExperience}</td>
                <td>{item.jobSkills.join(", ")}</td>
                <td>{item.isActive ? "Active" : "Inactive"}</td>
                <td>{item.date_of_Birth}</td>
                {/* <td>{new Date(item.date_of_Birth).toLocaleDateString()}</td> */}
               
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="14">No Data</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CategoryTable;
