import React from "react";
import { FiSettings } from "react-icons/fi";
import { Link } from "react-router-dom";
import ResumeCard from "./ResumeCard";

function PositionTable({data}) {
 
  return (
    <div className="pageTable">
      <div className="tableLength">
        <div className="dataTables_length d-flex justify-content-between pageHeader" style={{ padding: "6px 0" }}>
          <div className="pageTitle">
            <FiSettings />
            <h5>Resume List</h5>
          </div>
          <div>
            <select className="form-select">
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
            </select>
            <span className="record">records</span>
          </div>
        </div>
      </div>
      {data?.map((resume) => (
        <ResumeCard resume={resume} />
      ))}
    </div>
  );
}

export default PositionTable;
