import React, { useEffect, useState } from "react";
import { FiSettings } from "react-icons/fi";

import CategoryTable from "./CategoryTable";

const CVSList = ({ data, getCategoryData, searchData, getCategoryData2 }) => {
  const [val, setVal] = useState('')
  const [count, setCount] = useState([])
  useEffect(() => {
    if (data?.totalPages) {
      const arrr = []
      for (let i = 0; i < data.totalPages; i++) {
        arrr.push(i + 1)
      }
      setCount(arrr);
    }
  }, [data])
  return (
    <>
      <div className="pageTableWrapper">
        <div className="pageHeader">
          <div className="pageTitle">
            <FiSettings />
            <h5>CANDIDATES CSV IMPORT List   </h5>
          </div>
          {/* <div className="addNew">
            <Link to="/admin/add-category" className="btn btn-success">
              <AiOutlinePlus /> Add New Category
            </Link>
          </div> */}
        </div>
        {/* <div>
          <input className="form-control" onChange={(e) => { setVal(e.target.value) }} placeholder="Search" style={{ margin: "15px", width: "250px" }} />
          <button type="button" class="btn btn-primary" style={{ margin: "0 15px" }} onClick={() => { searchData(val) }}>Search</button>
        </div> */}

        <div className="pageBody">
          <CategoryTable data={data?.data} getCategoryData={getCategoryData} />
        </div>

        
        <nav aria-label="Page navigation example">
          <ul className="pagination" style={{margin:"18px"}}>
            {count && count?.map((val)=>{
              return <li className="page-item" onClick={()=>{getCategoryData2(val)}}><a className="page-link" href="#">{val}</a></li>
            })}
          </ul>
        </nav>

      </div>
    </>
  );
};

export default CVSList;
