import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Loaders } from '../../pages/Loaders';
import { useNavigate } from 'react-router-dom';

function SearchCandidate() {
    const [formData, setFormData] = useState({
        keywords: '',
        exclusiveKeywords: '',
        itSkills: '',
        experienceFrom: '',
        experienceTo: '',
        currentLocation: '',
        salaryFrom: '',
        salaryTo: '',
        age: '',
        name: '',
        email: '',
        date_of_Birth: '',
        country: '',
        state: '',
        city: '',
        nationality: '',
        gender: '',
        mobile: '',
        careerLevel: '',
        industry: '',
        functionalArea: '',
        jobExperience: '',
        expectedSalary: '',
        currentSalary: '',
        salaryCurrency: '',
    });

    const [load , setload] = useState(false)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const constructQueryParams = (data) => {
        const params = new URLSearchParams();

        // Map only non-empty fields to the query params
        Object.entries(data).forEach(([key, value]) => {
            if (value) {
                params.append(key, value);
                // if (key === 'experienceFrom' && data.experienceTo) {
                //     params.append('jobExperience', `${data.experienceFrom}-${data.experienceTo}`);
                // } else if (key === 'salaryFrom' && data.salaryTo) {
                //     params.append('expectedSalary', `${data.salaryFrom}-${data.salaryTo}`);
                // } else if (!['experienceTo', 'salaryTo'].includes(key)) {
                //     params.append(key, value);
                // }
            }
        });

        return params.toString();
    };

    const navigate = useNavigate()
    const handleSubmit = async (e) => {
        setload(true)
        e.preventDefault();
        const baseUrl = 'https://expert-eye.vercel.app/api/apply/job/';
        const queryParams = constructQueryParams(formData);
        const url = `${baseUrl}?${queryParams}`;
        setTimeout(() => {
            navigate(`/admin/list-resume/${queryParams}`)
            setload(false)
        }, 1000);
        return

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log('Response:', data);
        } catch (error) {
            console.error('Fetch error:', error);
        }
        setload(false)
    };

    return (
        // {loader && <Loaders/>}
        <div className="container">
            {load && <Loaders/>}
            <h4>Advance Search</h4>
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="keywords">
                            <Form.Label>Keywords</Form.Label>
                            <Form.Control
                                type="text"
                                name="keywords"
                                value={formData.keywords}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="exclusiveKeywords">
                            <Form.Label>Exclued </Form.Label>
                            <Form.Control
                                type="text"
                                name="exclusiveKeywords"
                                value={formData.exclusiveKeywords}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="exclusiveKeywords">
                            <Form.Label>Mobile </Form.Label>
                            <Form.Control
                                type="number"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="itSkills">
                            <Form.Label>IT Skills</Form.Label>
                            <Form.Control
                                type="text"
                                name="itSkills"
                                value={formData.itSkills}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group controlId="experienceFrom">
                            <Form.Label>Experience (From)</Form.Label>
                            <Form.Control
                                type="number"
                                name="experienceFrom"
                                value={formData.experienceFrom}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="experienceTo">
                            <Form.Label>Experience (To)</Form.Label>
                            <Form.Control
                                type="number"
                                name="experienceTo"
                                value={formData.experienceTo}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="currentLocation">
                            <Form.Label>Current Location</Form.Label>
                            <Form.Control
                                type="text"
                                name="currentLocation"
                                value={formData.currentLocation}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group controlId="salaryFrom">
                            <Form.Label>Annual Salary (From)</Form.Label>
                            <Form.Control
                                type="number"
                                name="salaryFrom"
                                value={formData.salaryFrom}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="salaryTo">
                            <Form.Label>Annual Salary (To)</Form.Label>
                            <Form.Control
                                type="number"
                                name="salaryTo"
                                value={formData.salaryTo}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="firstname">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="lastname">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="lastname"
                                value={formData.lastname}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>



                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="date_of_Birth">
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control
                                type="date"
                                name="date_of_Birth"
                                value={formData.date_of_Birth}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="country">
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                                type="text"
                                name="country"
                                value={formData.country}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="state">
                            <Form.Label>State</Form.Label>
                            <Form.Control
                                type="text"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                </Row>

                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="city">
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type="text"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="nationality">
                            <Form.Label>Nationality</Form.Label>
                            <Form.Control
                                type="text"
                                name="nationality"
                                value={formData.nationality}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="functionalArea">
                            <Form.Label>Functional Area</Form.Label>
                            <Form.Control
                                type="text"
                                name="functionalArea"
                                value={formData.functionalArea}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="gender">
                            <Form.Label>Gender</Form.Label>
                            <Form.Control
                                as="select"
                                name="gender"
                                value={formData.gender}
                                onChange={handleChange}
                            >
                                <option value="">Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group controlId="careerLevel">
                            <Form.Label>Career Level</Form.Label>
                            <Form.Control
                                type="text"
                                name="careerLevel"
                                value={formData.careerLevel}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="industry">
                            <Form.Label>Industry</Form.Label>
                            <Form.Control
                                type="text"
                                name="industry"
                                value={formData.industry}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                  
                </Row>

                <Row className="mb-3">
                  
                    <Col>
                        <Form.Group controlId="jobExperience">
                            <Form.Label>Job Experience</Form.Label>
                            <Form.Control
                                type="text"
                                name="jobExperience"
                                value={formData.jobExperience}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="expectedSalary">
                            <Form.Label>Expected Salary</Form.Label>
                            <Form.Control
                                type="text"
                                name="expectedSalary"
                                value={formData.expectedSalary}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="currentSalary">
                            <Form.Label>Current Salary</Form.Label>
                            <Form.Control
                                type="text"
                                name="currentSalary"
                                value={formData.currentSalary}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="salaryCurrency">
                            <Form.Label>Salary Currency</Form.Label>
                            <Form.Control
                                type="text"
                                name="salaryCurrency"
                                value={formData.salaryCurrency}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>


                <Button variant="primary" type="submit">
                    Search
                </Button>
            </Form>
        </div>
    );
}

export default SearchCandidate;
