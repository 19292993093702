import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { baseUrl } from '../../login/baseUrl';
import { getCompany, getIndustry, getJobSkills, getLocation } from '../../apifile/ApiFiles';

function SideFilterComp() {

    const [data, setData] = useState({
        location: [],
        keySkill: [],
        companys: [],
        industrys: [],
    });

    const getAllData = async () => {
        const clone = {...data }
        try {
            const res = await getLocation()
            clone.location = res.map((item) => {
                return { ...item, value: item.city, label: item.city }
            })
        } catch (error) {

        }
        try {
            const res2 = await getJobSkills()
            clone.keySkill = res2.map((item) => {
                return { ...item, value: item.job_skills, label: item.job_skills }
            })
        } catch (error) {

        }
        try {
            const res3 = await getCompany()
            clone.companys = res3.data.map((item) => {
                return { ...item, value: item.name, label: item.name }
            })
            
        } catch (error) {

        }
        try {
            const res4 = await getIndustry()
            
            clone.industrys = res4.data.map((item) => {
                return { ...item, value: item.industry, label: item.industry }
            })

        } catch (error) {

        }

        setData(clone)
    };

    useEffect(() => {
        getAllData();
    }, []);

    return (
        <div className="sideFilterComp">
            <div className="sideFilterCompHeader">
                <h4 style={{ backgroundColor: "#b3cc34", textAlign: "center", color: "white" }}>Filter</h4>
            </div>
            <div className="sideFilterCompBody row">
                {/* Premium Institute Candidates */}
                <div className="col-md-12">
                    <Form>
                        <div className="mb-1">
                            <Form.Check type={'checkbox'} id={`check-api-checkbox`}>
                                <Form.Check.Input type={'checkbox'} isValid />
                                <Form.Check.Label>Premium Institute Candidates</Form.Check.Label>
                            </Form.Check>
                        </div>
                    </Form>
                </div>

                {/* Candidates with verified skills */}
                <div className="col-md-12">
                    <Form>
                        <div className="mb-1">
                            <Form.Check type={'checkbox'} id={`check-api-checkbox1`}>
                                <Form.Check.Input type={'checkbox'} isValid />
                                <Form.Check.Label>Candidates with verified skills</Form.Check.Label>
                            </Form.Check>
                        </div>
                    </Form>
                </div>

                {/* Keywords - Multiselect */}
                <div className="col-md-12">
                    <Form.Group className="mb-1">
                        <Form.Label>Keywords</Form.Label>
                        <Select
                            isMulti
                            options={data?.keySkill}
                            classNamePrefix="select"
                        />
                    </Form.Group>
                </div>

                {/* Location - Multiselect */}
                <div className="col-md-12">
                    <Form.Group className="mb-1">
                        <Form.Label>Location</Form.Label>
                        <Select
                            isMulti
                            options={data?.location}
                            classNamePrefix="select"
                        />
                    </Form.Group>
                </div>


                {/* Experience (Years) */}
                <div className="col-md-12">
                    <Form.Group className="mb-1">
                        <Form.Label>Experience (Years)</Form.Label>
                        <div className="d-flex">
                            <Form.Control type="number" placeholder="From" className="me-2" />
                            <Form.Control type="number" placeholder="To" />
                        </div>
                    </Form.Group>
                </div>

                {/* Salary (INR-Lacs) */}
                <div className="col-md-12">
                    <Form.Group className="mb-1">
                        <Form.Label>Salary (INR-Lacs)</Form.Label>
                        <div className="d-flex">
                            <Form.Control type="number" placeholder="From" className="me-2" />
                            <Form.Control type="number" placeholder="To" />
                        </div>
                    </Form.Group>
                </div>

                {/* Current Company */}
                <div className="col-md-12">
                    <Form.Group className="mb-1">
                        <Form.Label>Company</Form.Label>
                        <Select
                            isMulti
                            options={data?.companys}
                            classNamePrefix="select"
                        />
                    </Form.Group>
                </div>
                {/* Industry */}
                <div className="col-md-12">
                    <Form.Group className="mb-1">
                        <Form.Label>Industry</Form.Label>
                        <Select
                            isMulti
                            options={data?.industrys}
                            classNamePrefix="select"
                        />
                    </Form.Group>
                </div>
                {/* Current Designation */}
                <div className="col-md-12">
                    <Form.Group className="mb-1">
                        <Form.Label>Designation</Form.Label>
                        <Form.Control type="text" placeholder="Enter designation" />
                    </Form.Group>
                </div>

                {/* Department and Role */}
                <div className="col-md-12">
                    <Form.Group className="mb-1">
                        <Form.Label>Department and Role</Form.Label>
                        <Form.Control type="text" placeholder="Enter department and role" />
                    </Form.Group>
                </div>


            </div>
        </div>
    );
}

export default SideFilterComp;
