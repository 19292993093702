import React from "react";
import PageBar from "../../components/dashboard/page-bar/PageBar";
import HistoryNotificationList from "../../components/historynotification/HistoryNotificationList";

function ListNoioficationHistory() {
  return (
    <>
      <div className="pageWrapper">
        <PageBar title="Notification History" />
        
        <HistoryNotificationList />
      </div>
    </>
  );
}

export default ListNoioficationHistory;
