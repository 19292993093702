import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles for the editor

function BulkModel(props) {
    const [activeTab, setActiveTab] = useState('email');
    const [emailMessage, setEmailMessage] = useState('');
    const [whatsappMessage, setWhatsappMessage] = useState('');
    const [smsMessage, setSmsMessage] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        // You can handle the form submit logic here for each message type
        if (activeTab === 'email') {
            console.log("Email Message: ", emailMessage);
        } else if (activeTab === 'whatsapp') {
            console.log("WhatsApp Message: ", whatsappMessage);
        } else if (activeTab === 'message') {
            console.log("SMS Message: ", smsMessage);
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Bulk Message
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container mt-4">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={activeTab}
                        onSelect={(k) => setActiveTab(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="email" title="Email">
                            <h3>Email Section</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="emailMessage" className="form-label">
                                        Message
                                    </label>
                                    <ReactQuill 
                                        theme="snow" 
                                        value={emailMessage} 
                                        onChange={setEmailMessage} 
                                        placeholder="Enter email message" 
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary">Send Email</button>
                            </form>
                        </Tab>

                        <Tab eventKey="whatsapp" title="WhatsApp">
                            <h3>WhatsApp Section</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="whatsappMessage" className="form-label">
                                        Message
                                    </label>
                                    <ReactQuill 
                                        theme="snow" 
                                        value={whatsappMessage} 
                                        onChange={setWhatsappMessage} 
                                        placeholder="Enter WhatsApp message" 
                                    />
                                </div>
                                <button type="submit" className="btn btn-success">Send WhatsApp Message</button>
                            </form>
                        </Tab>

                        <Tab eventKey="message" title="Message">
                            <h3>Message Section</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="smsMessage" className="form-label">
                                        Message
                                    </label>
                                    <ReactQuill 
                                        theme="snow" 
                                        value={smsMessage} 
                                        onChange={setSmsMessage} 
                                        placeholder="Enter SMS message" 
                                    />
                                </div>
                                <button type="submit" className="btn btn-info">Send SMS</button>
                            </form>
                        </Tab>
                    </Tabs>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default BulkModel;
