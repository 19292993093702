import React, { useEffect, useState } from "react";
import { FiSettings } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { addJob, geteditjob, updateJob } from "../../apifile/ApiFiles";
import { Loaders } from "../../../pages/Loaders";

const CreateJob = ({ allState, getAllData }) => {
  const [data, setData] = useState({
    jobTitle: "",
    company_id: "",
    jobDescription: "",
    jobBenefits: "",
    jobSkills: "",
    country: "",
    state: "",
    city: "",
    careerLevel: "",
    functionalArea: "",
    jobType: "",
    jobShift: "",
    gender: "",
    degreeLevel: "",
    experience: "",
    salary: {
      from: '',
      to: '',
      currency: "",
      period: "",
      hide: false,
    },
    positions: 3,
    expiryDate: "",
    isFreelance: false,
    isFeatured: true,
    isActive: true,
  });

  const onchangeHandle = (e) => {
    const clone = { ...data };
    clone[e.target.name] = e.target.value;
    setData(clone);
  };

  const navigate = useNavigate();
  const notify = (message) => toast(message);

  const params = useParams();

  const getById = async () => {
    if (params?.id) {
      try {
        const res = await geteditjob(params.id)
        setData({
          ...res?.data, salary_from: data.salary.from,
          salary_to: data.salary.to,
        });
        getAllData()
      } catch (error) {
        console.error("Failed to fetch job details:", error);
      }
    }
  };

  useEffect(() => {
    getById();
  }, [params.id]);
  const [loader, setLoader] = useState(false)
  const sendData = async () => {
    setLoader(true)
    const clone = {
      ...data, salary: {
        salary_from: data.salary_from,
        salary_to: data.salary_to,
        currency: data.currency_id,
        period: data.currency_id,
        hide: false,
      },
    }
    try {
      const res = await addJob(clone)
      if (res.status_code == 200) {
        notify("Job added  Successfull");
      } else {
        alert(res.message)
        setLoader(false)
        return
      }
      setTimeout(() => {
        navigate("/admin/list-jobs");
      }, 1000);
    } catch (error) {
      alert("Failed to add job");
      console.error(error);
    }
    setLoader(false)
  };

  const submitDataUpdate = async () => {
    setLoader(true)

    const clone = {
      ...data, salary: {
        from: data.salary_from,
        to: data.salary_to,
        currency: data.currency_id,
        period: data.currency_id,
        hide: false,
      },
    }

    try {
      const res = await updateJob(params.id, clone)
      if (res.status_code == 200) {
        notify("Job updated  Successfull");
      } else {
        alert(res.message)
        setLoader(false)
        return
      }
      setTimeout(() => {
        navigate("/admin/list-jobs");
      }, 1000);
    } catch (error) {
      alert("Failed to update job");
      console.error(error);
    }
  };

  return (
    <>
      {loader && <Loaders />}
      <ToastContainer />
      <div className="pageTableWrapper">
        <div className="pageHeader">
          <div className="pageTitle">
            <FiSettings />
            <h5>Job Form</h5>
          </div>
        </div>

        <div className="pageBody">
          <div className="pageTable">
            <form>
              <div className="form-group mb-3">
                <label htmlFor="lang" className="mb-1">
                  <strong>Company</strong>
                </label>
                <select className="form-select" id="lang" value={data?.company_id} name="company_id" onChange={onchangeHandle}>
                  <option value>Select Company</option>
                  {allState?.company_id &&
                    allState?.company_id?.map((item) => {
                      return <option key={item._id} value={item._id}>{item.name}</option>;
                    })}
                </select>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="language_level">
                  <strong>Job Title</strong>
                </label>
                <input
                  className="form-control"
                  placeholder="Job Title"
                  type="text"
                  value={data.jobTitle}
                  name="jobTitle"
                  onChange={onchangeHandle}
                />
              </div>

              <div className="form-group mb-3">
                <label htmlFor="language_level">
                  <strong>Job description</strong>
                </label>
                <textarea
                  cols="30"
                  rows="4"
                  className="form-control"
                  placeholder="Enter you Job Description"
                  value={data.jobDescription}
                  name="jobDescription"
                  onChange={onchangeHandle}
                ></textarea>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="language_level">
                  <strong>Benefits</strong>
                </label>
                <textarea
                  cols="30"
                  rows="4"
                  className="form-control"
                  placeholder="Benefits"
                  value={data.jobBenefits}
                  name="jobBenefits"
                  onChange={onchangeHandle}
                ></textarea>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="lang" className="mb-1">
                  <strong>Job Skills</strong>
                </label>
                <select className="form-select" id="lang" value={data?.jobSkills} name="jobSkills" onChange={onchangeHandle}>
                  <option value>Select Skill</option>
                  {allState?.skills_id &&
                    allState?.skills_id?.map((item) => {
                      return <option key={item._id} value={item._id}>{item.job_skills}</option>;
                    })}
                </select>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="lang" className="mb-1">
                  <strong>Country</strong>
                </label>
                <select className="form-select" id="lang" value={data?.country} name="country" onChange={onchangeHandle}>
                  <option value>Select Country</option>
                  {allState?.country_id &&
                    allState?.country_id?.map((item) => {
                      return <option key={item._id} value={item._id}>{item.name ? item.name : item?.country}</option>;
                    })}
                </select>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="lang" className="mb-1">
                  <strong>State</strong>
                </label>
                <select className="form-select" id="lang" value={data?.state} name="state" onChange={onchangeHandle}>
                  <option value>Select State</option>
                  {allState?.states_id &&
                    allState?.states_id?.map((item) => {
                      return <option key={item._id} value={item._id}>{item.state}</option>;
                    })}
                </select>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="lang" className="mb-1">
                  <strong>City</strong>
                </label>
                <select className="form-select" id="lang" value={data?.city} name="city" onChange={onchangeHandle}>
                  <option value>Select City</option>
                  {allState?.city_id &&
                    allState?.city_id?.map((item) => {
                      return <option key={item._id} value={item._id}>{item.city}</option>;
                    })}
                </select>
              </div>


              <div className="form-group mb-3">
                <label htmlFor="lang" className="mb-1">
                  <strong>Career Level</strong>
                </label>

                <select className="form-select" id="lang" value={data?.careerLevel} name="careerLevel" onChange={onchangeHandle}>
                  <option value>Select Career level</option>
                  {allState?.carrier_level_id &&
                    allState?.carrier_level_id?.map((item) => {
                      return <option key={item._id} value={item._id}>{item.career_level}</option>;
                    })}
                </select>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="lang" className="mb-1">
                  <strong>Salary From</strong>
                </label>
                <input type="number" className="form-control" value={data.salary_from}
                  name="salary_from"
                  onChange={onchangeHandle} />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="lang" className="mb-1">
                  <strong>Salary To</strong>
                </label>
                <input type="number" className="form-control" value={data.salary_to}
                  name="salary_to"
                  onChange={onchangeHandle} />
              </div>

              <div className="form-group mb-3">
                <label htmlFor="lang" className="mb-1">
                  <strong>Salary Currency</strong>
                </label>
                <select className="form-select" id="lang" value={data?.currency_id} name="currency_id" onChange={onchangeHandle}>
                  <option value>Select Currency</option>
                  {allState?.currency_id &&
                    allState?.currency_id?.map((item) => {
                      return <option key={item._id} value={item._id}>{item.currency}</option>;
                    })}
                </select>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="lang" className="mb-1">
                  <strong>Salary Period</strong>
                </label>
                <select className="form-select" id="lang" value={data?.salary_period_id} name="salary_period_id" onChange={onchangeHandle}>
                  <option value>Select salary period</option>
                  {allState?.salery_periods_id &&
                    allState?.salery_periods_id?.map((item) => {
                      return <option key={item._id} value={item._id}>{item.salary_period}</option>;
                    })}
                </select>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="hide_salary">
                  <strong>Hide Salary?</strong>
                </label>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    defaultChecked
                    onChange={onchangeHandle}
                    value={1}
                    name="hide_salary"
                    checked={data?.hide_salary == 1}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" onChange={onchangeHandle}
                    value={0}
                    checked={data?.hide_salary == 0}
                    name="hide_salary" />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    No
                  </label>
                </div>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="lang" className="mb-1">
                  <strong>Functional Area</strong>
                </label>
                <select className="form-select" id="lang" value={data?.functionalArea} name="functionalArea" onChange={onchangeHandle}>
                  <option value>Select functional area</option>
                  {allState?.functional_area_id &&
                    allState?.functional_area_id?.map((item) => {
                      return <option key={item._id} value={item._id}>{item.functional_area}</option>;
                    })}
                </select>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="lang" className="mb-1">
                  <strong>Job Type</strong>
                </label>
                <select className="form-select" id="lang" value={data?.jobType} name="jobType" onChange={onchangeHandle}>
                  <option value>Select Job Type</option>
                  {allState?.job_type_id &&
                    allState?.job_type_id?.map((item) => {
                      return <option key={item._id} value={item._id}>{item.job_type}</option>;
                    })}
                </select>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="lang" className="mb-1">
                  <strong>Job Shift</strong>
                </label>
                <select className="form-select" id="lang" value={data?.jobShift} name="jobShift" onChange={onchangeHandle}>
                  <option value>Select job shift</option>
                  {allState?.job_shift_id &&
                    allState?.job_shift_id?.map((item) => {
                      return <option key={item._id} value={item._id}>{item.job_shift}</option>;
                    })}
                </select>
              </div>


              <div className="form-group mb-3">
                <label htmlFor="lang" className="mb-1">
                  <strong>Gender</strong>
                </label>
                <select className="form-select" id="lang" value={data?.gender} name="gender" onChange={onchangeHandle}>
                  <option value>Select Gender</option>
                  {allState?.gender_id &&
                    allState?.gender_id?.map((item) => {
                      return <option key={item._id} value={item._id}>{item.gender}</option>;
                    })}
                </select>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="lang" className="mb-1">
                  <strong>Job expiry date</strong>
                </label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Job Expiry Date"
                  value={data.expiryDate}
                  name="expiryDate"
                  onChange={onchangeHandle}
                />
              </div>

              <div className="form-group mb-3">
                <label htmlFor="lang" className="mb-1">
                  <strong>Required Degree Level</strong>
                </label>
                <select className="form-select" id="lang" value={data?.degreeLevel} name="degreeLevel" onChange={onchangeHandle}>
                  <option value>Select degree level</option>
                  {allState?.degree_level_id &&
                    allState?.degree_level_id?.map((item) => {
                      return <option key={item._id} value={item._id}>{item.degree_level}</option>;
                    })}
                </select>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="lang" className="mb-1">
                  <strong>Required job experience</strong>
                </label>
                <select className="form-select" id="lang" value={data?.experience} name="experience" onChange={onchangeHandle}>
                  <option value>Select Job Exp</option>
                  {allState?.job_experience &&
                    allState?.job_experience?.map((item) => {
                      return <option key={item._id} value={item._id}>{item.job_experience}</option>;
                    })}
                </select>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="is_default">
                  <strong>Is featured?</strong>
                </label>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    defaultChecked
                    onChange={onchangeHandle}
                    value={1}
                    name="is_featured"
                    checked={data?.is_featured == 1}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Yes
                  </label>
                </div>
              </div>

              {/* Submit Buttons */}
              <div>
                {params?.id ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={submitDataUpdate}
                  >
                    Update Job
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={sendData}
                  >
                    Add Job
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateJob;
