import axios from "axios";
import React, { useEffect, useState } from "react";

function SelectedCandidateForm() {
  const [formData, setFormData] = useState({
    name: "",
    job_category: "",
    company: "",
    mobile: "",
    mobile2: "",
    mofa_no: "",
    visa_no: "",
    passport_no: "",
    flight_date: "",
    marital_status: "",
    employee_name: "",
    charges: "",
    photo: null,
    agreement: null,
    video: null
  });

  const [categoryData, setCategoryData] = useState([]);

  const getCategoryData = async () => {
    try {
      const res = await axios.get(
        `https://abaris-j-p-backend.vercel.app/api/job-category/all`
      );
      setCategoryData(res?.data);
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  useEffect(() => {
    getCategoryData();
  }, []);

  const handleChange = async (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      const imageForm = new FormData();
      const file = files[0]
      imageForm.append("image", file);
      const base64 = await axios.post(
        "https://metrimoni-front.vercel.app/api/upload/add",
        imageForm
      );
      console.log(base64.data);

      setFormData((prevData) => ({
        ...prevData,
        [name]: base64.data.imageUrl,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSubmit = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSubmit.append(key, formData[key]);
    });

    try {
      const response = await axios.post(
        " https://job-portal-seven-chi.vercel.app/api/candidate/selected-candidate",
        formDataToSubmit,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      alert("Form submitted successfully:", response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  return (
    <div className="pageTable">
      <form>
        <div className="row mb-3">
          <div className="form-group mb-3 col-xl-3 col-lg-3 col-md-6">
            <label htmlFor="name" className="mb-1">
              <strong>Name</strong>
            </label>
            <input
              className="form-control"
              placeholder="Name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mb-3 col-xl-3 col-lg-3 col-md-6">
            <label htmlFor="job_category" className="mb-1">
              <strong>Category</strong>
            </label>
            <select
              className="form-select"
              name="job_category"
              value={formData.job_category}
              onChange={handleChange}
            >
              <option>Select Category</option>
              {categoryData?.data?.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.category_name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group mb-3 col-xl-3 col-lg-3 col-md-6">
            <label htmlFor="company" className="mb-1">
              <strong>Company Name</strong>
            </label>
            <input
              className="form-control"
              placeholder="Company Name"
              type="text"
              name="company"
              value={formData.company}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mb-3 col-xl-3 col-lg-3 col-md-6">
            <label htmlFor="mobile" className="mb-1">
              <strong>Mobile 1</strong>
            </label>
            <input
              className="form-control"
              placeholder="+91 8294 787277"
              type="number"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mb-3 col-xl-3 col-lg-3 col-md-6">
            <label htmlFor="mobile2" className="mb-1">
              <strong>Mobile 2</strong>
            </label>
            <input
              className="form-control"
              placeholder="+91 9294 787252"
              type="number"
              name="mobile2"
              value={formData.mobile2}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mb-3 col-xl-3 col-lg-3 col-md-6">
            <label htmlFor="mofa_no" className="mb-1">
              <strong>Mofa No</strong>
            </label>
            <input
              className="form-control"
              placeholder="Mofa No"
              type="text"
              name="mofa_no"
              value={formData.mofa_no}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mb-3 col-xl-3 col-lg-3 col-md-6">
            <label htmlFor="visa_no" className="mb-1">
              <strong>Visa No</strong>
            </label>
            <input
              className="form-control"
              placeholder="Visa No"
              type="text"
              name="visa_no"
              value={formData.visa_no}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mb-3 col-xl-3 col-lg-3 col-md-6">
            <label htmlFor="passport_no" className="mb-1">
              <strong>Passport No</strong>
            </label>
            <input
              className="form-control"
              placeholder="Passport Number"
              type="text"
              name="passport_no"
              value={formData.passport_no}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mb-3 col-xl-3 col-lg-3 col-md-6">
            <label htmlFor="flight_date" className="mb-1">
              <strong>Flight Date</strong>
            </label>
            <input
              className="form-control"
              placeholder="Flight Date"
              type="date"
              name="flight_date"
              value={formData.flight_date}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mb-3 col-xl-3 col-lg-3 col-md-6">
            <label htmlFor="marital_status" className="mb-1">
              <strong>Marital Status</strong>
            </label>
            <input
              className="form-control"
              placeholder="Marital Status"
              type="text"
              name="marital_status"
              value={formData.marital_status}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mb-3 col-xl-3 col-lg-3 col-md-6">
            <label htmlFor="employee_name" className="mb-1">
              <strong>Employee Name</strong>
            </label>
            <input
              className="form-control"
              placeholder="Employee Name"
              type="text"
              name="employee_name"
              value={formData.employee_name}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mb-3 col-xl-3 col-lg-3 col-md-6">
            <label htmlFor="charges" className="mb-1">
              <strong>Service Charge</strong>
            </label>
            <input
              className="form-control"
              placeholder="Service Charge"
              type="text"
              name="charges"
              value={formData.charges}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mb-3 col-xl-3 col-lg-3 col-md-6">
            <label htmlFor="photo" className="mb-1">
              <strong>Upload Passport Photo</strong>
            </label>
            <input
              className="form-control"
              type="file"
              name="photo"
              onChange={handleChange}
              accept="image/*"
            />
          </div>

          <div className="form-group mb-3 col-xl-3 col-lg-3 col-md-6">
            <label htmlFor="agreement" className="mb-1">
              <strong>Upload Agreement</strong>
            </label>
            <input
              className="form-control"
              type="file"
              name="agreement"
              onChange={handleChange}
              accept="image/*"
            />
          </div>

          <div className="form-group mb-3 col-xl-3 col-lg-3 col-md-6">
            <label htmlFor="video" className="mb-1">
              <strong>Upload Video</strong>
            </label>
            <input
              className="form-control"
              type="file"
              name="video"
              onChange={handleChange}
              accept="image/*"
            />
          </div>
        </div>

        <button type="button" onClick={handleSubmit} className="btn btn-primary">
          Submit
        </button>
      </form >
    </div >
  );
}

export default SelectedCandidateForm;
